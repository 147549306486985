import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { Alert, Col, Row } from "reactstrap";

import { AppState } from "../../store";
import { IInvitation } from "../../store/invitations/types";

import { deleteUser, userSearch, userCount } from "../../actions/user/actions";

import ModaleModifica from "../../components/Utenti/ModaleModifica";
import ModaleElimina from "../../components/Utenti/ModaleElimina";

import TableComponent from "./components/Table/Table";
import InterfacciaRicerca from "./components/Interfaccia/InterfacciaRicerca";
import applyFilters from "./components/Interfaccia/applyFilters";
import InfoLicenze from "./components/Licenze/InfoLicenze";

import { useOnEdit } from "./helpers/customHooks/useOnEdit";
import calculatePagesFromCount from "./helpers/calculatePagesFromCount";

import { Props, UtentiProps } from "./types/utenti";

export default function InvitiPage(props: Props): JSX.Element {
  // Redux state selectors
  const user = useSelector((state: AppState) => state.user.current);
  const licenses = useSelector((state: AppState) => state.invitations.licenses);

  // Redux dispatch
  const thunkDispatch = useDispatch<ThunkDispatch<AppState, any, AnyAction>>();

  // Custom hooks
  const onEdit = useOnEdit();

  // Filters state
  const [orderFiltersArray, setOrderFiltersArray] = useState<Array<number>>([
    0, 0, 0,
  ]);
  const [solutionsFilterArray, setSolutionsFilterArray] = useState<Array<any>>(
    []
  );
  const [userTypeFilterArray, setUserTypeFilterArray] = useState<Array<any>>([
    { value: false, label: "ADMINISTRATOR" },
    { value: false, label: "USER" },
  ]);
  const [queryString, setQueryString] = useState<string>("");
  const [typeFilter, setTypeFilter] = useState<string>("");
  const [solutionFilter, setSolutionsFilter] = useState<string>("");
  const [searchText, setSearchText] = useState<string>("");

  // Dropdown state
  const [dropSoluzioni, setDropSoluzioni] = useState<boolean>(false);
  const [dropTipo, setDropTipo] = useState<boolean>(false);

  // Modal state
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [editUser, setEditUser] = useState<IInvitation | undefined>(undefined);
  const [isEliminaModalOpen, setEliminaModal] = useState<boolean>(false);
  const [userIDToDelete, setUserIDToDelete] = useState<Number>();

  // Alert state
  const [activationAlert, setActivationAlert] = useState<any>({
    isOpen: false,
    date: null,
  });

  // Pagination state
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [pagLastIndex, setPagLastIndex] = useState<number>(0);

  const commonProps: UtentiProps = {
    user,
    props,
    queryString,
    pagination: {
      currentPage,
      setCurrentPage,
      totalPages,
      setTotalPages,
      pagLastIndex,
      setPagLastIndex,
    },
    filters: {
      orderFiltersArray,
      setOrderFiltersArray,
      userTypeFilterArray,
      setUserTypeFilterArray,
      solutionsFilterArray,
      setSolutionsFilterArray,
      searchText,
      setSearchText,
      queryString,
      setQueryString,
      typeFilter,
    },
    dropdowns: {
      dropTipo,
      setDropTipo,
      dropSoluzioni,
      setDropSoluzioni,
    },
    setters: {
      setSolutionsFilter,
      setTypeFilter,
      setActivationAlert,
      setEditUser,
      setOpenEditModal,
      setUserIDToDelete,
      setEliminaModal,
    },
  };

  useEffect(() => {
    if (user.selectedCompany !== null && props.selectedService !== null) {
      onEdit(
        user,
        props,
        currentPage,
        queryString,
        setTotalPages,
        setPagLastIndex
      );
      setCurrentPage(1);
    }
  }, [props.selectedService]);

  useEffect(() => {
    if (user.selectedCompany !== null && props.selectedService !== null) {
      onEdit(
        user,
        props,
        currentPage,
        queryString,
        setTotalPages,
        setPagLastIndex
      );
    }
  }, [currentPage]);

  useEffect(() => {
    applyFilters(0, commonProps);
  }, [orderFiltersArray]);

  useEffect(() => {
    // if (queryString !== '') {
    if (user.selectedCompany !== null && props.selectedService !== null) {
      thunkDispatch(
        userCount(
          props.selectedService.id,
          user.selectedCompany.company.id,
          currentPage,
          queryString
        )
      ).then((res: any) => {
        calculatePagesFromCount(
          res.payload.data,
          setTotalPages,
          setPagLastIndex
        );
      });
      thunkDispatch(
        userSearch(
          props.selectedService.id,
          user.selectedCompany.company.id,
          currentPage,
          queryString
        )
      );
      // }
    }
  }, [queryString]);

  const chiudiModaleElimina = () => {
    setEliminaModal(false);
  };

  const eliminaUtente = () => {
    if (userIDToDelete !== undefined) {
      thunkDispatch(deleteUser(userIDToDelete)).then(() => {
        onEdit(
          user,
          props,
          currentPage,
          queryString,
          setTotalPages,
          setPagLastIndex
        );
        chiudiModaleElimina();
      });
    }
  };

  const chiudiModale = () => {
    setOpenEditModal(false);
    onEdit(
      user,
      props,
      currentPage,
      queryString,
      setTotalPages,
      setPagLastIndex
    );
  };

  return (
    <>
      <div>
        <Alert isOpen={activationAlert.isOpen} color="danger">
          {activationAlert.date}
        </Alert>
        <ModaleModifica
          selectedService={props.selectedService}
          selectedCompany={props.selectedCompany}
          editUser={editUser}
          serviceCode={props.selectedService.code}
          isModalOpen={openEditModal}
          closeModal={chiudiModale}
        />
        <ModaleElimina
          isModalOpen={isEliminaModalOpen}
          closeModal={chiudiModaleElimina}
          delete={eliminaUtente}
        />
        <InfoLicenze licenses={licenses} />
        <InterfacciaRicerca {...commonProps} />
        <Row className="riga-console-tabella">
          <Col className="colonna-console-tabella" xs="12" md="12">
            <TableComponent {...commonProps} />
          </Col>
        </Row>
      </div>
    </>
  );
}
