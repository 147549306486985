import React from "react";
import { Button, Col, Row, Spinner } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

import { dictionaryGet } from "../../../common/functions";

import Maintenance from "../../../components/Maintenence/Maintenence";

import { DownloadCardProps } from "../types/download";

export const DownloadCard: React.FC<DownloadCardProps> = ({
  title,
  logoPath,
  version,
  dictionary,
  isLoading,
  onDownload,
  isMaintenance = false,
}) => {
  return (
    <Row style={{ marginBottom: "30px" }}>
      <Col>
        <div className="card-container card-download">
          <div className="card-download body">
            <div className="div-logo">
              <img src={logoPath} alt={title} />
            </div>
            <div className="div-description">
              <p
                style={{
                  color: "rgb(0, 80, 117)",
                  fontFamily: "Cairo-SemiBold",
                  fontSize: "24px",
                }}
              >
                {title}
              </p>
              <p>
                {dictionaryGet(dictionary, "global.console.download.version")}{" "}
                {version}
              </p>
            </div>
          </div>
          {isMaintenance ? (
            <Maintenance />
          ) : (
            <div className="div-download">
              <p className="box-bottom">
                <Button
                  disabled={isLoading}
                  onClick={onDownload}
                  className="btn-primary-viola"
                  color="primary"
                  size="md"
                >
                  {isLoading ? (
                    <Spinner style={{ width: "1rem", height: "1rem" }} />
                  ) : (
                    <>
                      <FontAwesomeIcon
                        style={{ marginRight: "5px" }}
                        icon={faDownload}
                      />
                      {dictionaryGet(
                        dictionary,
                        "global.console.download.download"
                      )}
                    </>
                  )}
                </Button>
              </p>
            </div>
          )}
        </div>
      </Col>
    </Row>
  );
};
