export enum Route {
  Download = "download",
}

export enum Section {
  BiApp = "biApp",
  BiUtilities = "biUtilities",
}

export type maintenanceType = {
  id: number;
  route: Route.Download | string;
  sections: Section[];
  underMaintenance: boolean;
};
