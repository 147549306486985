import React from "react";

import { getInstallerBiVersion } from "../../../actions/download/actions";
import { INSTALLER_CODE } from "../../../common/constants";
import { dictionaryGet } from "../../../common/functions";
import { ICurrent } from "../../../store/user/types";
import { Installer } from "../types/download";
import { downloadQlikHistoryVersion } from "./downloadQlikFunctions";

export const renderQlikHistoryContent = (
  installers: Installer | null,
  dictionary: any,
  current: ICurrent,
  thunkDispatch: any
) => {
  if (
    installers != null &&
    installers.QLIK.legacy != null &&
    installers.QLIK.legacy.length > 0
  ) {
    let versions = installers.QLIK.legacy.map((h: any) => {
      return (
        <p>
          <a
            onClick={() => {
              downloadQlikHistoryVersion(
                INSTALLER_CODE.BI,
                h.fileName,
                current,
                thunkDispatch
              );
            }}
          >
            {dictionaryGet(
              dictionary,
              "global.console.download.download_version"
            )}
            {h.version}
          </a>
        </p>
      );
    });

    return <>{versions}</>;
  }
};

export const renderBIHistory = (
  current: ICurrent,
  dictionary: any,
  thunkDispatch: any,
  setIsLoading: (loading: boolean) => void
) => {
  const manualDate = "2023.02.03";
  return (
    <p>
      <a
        onClick={() => {
          if (current.selectedCompany !== null) {
            return thunkDispatch(
              getInstallerBiVersion(
                current.selectedCompany.company.id,
                current.selectedCompany.company.guid
              )
            ).then((res: any) => {
              setIsLoading(false);
            });
          }
        }}
      >
        {dictionaryGet(dictionary, "global.console.download.download_version")}{" "}
        {manualDate}
      </a>
      <br />
    </p>
  );
};
