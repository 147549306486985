import React, { useState } from "react";
import { ILicensesWrapper } from "../../../../store/invitations/types";
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";

const RenderUsedLicensesDropdown = (licenses: ILicensesWrapper) => {
  const [usedLicenseDropdown, setusedLicenseDropdown] =
    useState<boolean>(false);

  if (licenses.data !== null) {
    let dropItems = licenses.data.map((lice, i) => {
      return (
        <React.Fragment key={i}>
          <DropdownItem key={i}>
            <span>{lice.product.name}</span>
            <span
              style={{
                color: "rgb(218, 88, 72)",
                marginLeft: "30px",
              }}
            >
              <b>{lice.totalLicences - lice.tempBalance}</b>
            </span>
          </DropdownItem>
        </React.Fragment>
      );
    });
    return (
      <ButtonDropdown
        className="dropdown-licenze"
        isOpen={usedLicenseDropdown}
        toggle={() => {
          setusedLicenseDropdown(!usedLicenseDropdown);
        }}
      >
        <DropdownToggle>
          <FontAwesomeIcon icon={faEllipsisH} />
        </DropdownToggle>
        <DropdownMenu bottom right>
          {dropItems}
        </DropdownMenu>
      </ButtonDropdown>
    );
  }
};

export default RenderUsedLicensesDropdown;
