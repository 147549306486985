import { getInstallerQlikVersion } from "../../../actions/download/actions";
import { INSTALLER_CODE } from "../../../common/constants";
import { ICurrent } from "../../../store/user/types";

export const downloadQlikHistoryVersion = (
  type: INSTALLER_CODE,
  fileName: "",
  current: ICurrent,
  thunkDispatch: any
) => {
  if (current.selectedCompany !== null) {
    return thunkDispatch(
      getInstallerQlikVersion(current.selectedCompany.company.id, fileName)
    ).then((res: any) => {
      window.open(res.payload.data);
    });
  }
};
