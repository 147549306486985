import React, { useState } from "react";
import { ILicensesWrapper } from "../../../../store/invitations/types";
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";

const RenderAvailableLicenseDropdown = (licenses: ILicensesWrapper) => {
  const [availableLicensesDropdown, setAvailableLicensesDropdown] =
    useState<boolean>(false);

  if (licenses.data !== null) {
    let dropItems = licenses.data.map((lice, i) => {
      return (
        <React.Fragment key={i}>
          <DropdownItem>
            <span>{lice.product.name}</span>
            <span style={{ color: "rgb(119, 188, 92)", marginLeft: "30px" }}>
              <b>{lice.totalLicences}</b>
            </span>
          </DropdownItem>
        </React.Fragment>
      );
    });
    return (
      <ButtonDropdown
        className="dropdown-licenze"
        isOpen={availableLicensesDropdown}
        toggle={() => {
          setAvailableLicensesDropdown(!availableLicensesDropdown);
        }}
      >
        <DropdownToggle>
          <FontAwesomeIcon icon={faEllipsisH} />
        </DropdownToggle>
        <DropdownMenu bottom right>
          {dropItems}
        </DropdownMenu>
      </ButtonDropdown>
    );
  }
};

export default RenderAvailableLicenseDropdown;
