import {
  faSort,
  faSortDown,
  faSortUp,
} from "@fortawesome/free-solid-svg-icons";

const renderFilter = (idx: number, orderFiltersArray: number[]) => {
  switch (orderFiltersArray[idx]) {
    case 0:
      return faSort;
    case 1:
      return faSortDown;
    case 2:
      return faSortUp;
    default:
      return faSort;
  }
};

export default renderFilter;
