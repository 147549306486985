import React, { useState } from "react";
import { Col, Table } from "reactstrap";

import { UtentiProps } from "../../types/utenti";
import TableHeader from "./TableHeader";
import TableBody from "./TableBody";

const TableComponent = ({
  user,
  props,
  queryString,
  ...rest
}: UtentiProps): JSX.Element => {
  const {
    pagination: {
      currentPage,
      setCurrentPage,
      totalPages,
      pagLastIndex,
      setPagLastIndex,
    },
  } = rest;

  const [checkArray, setCheckArray] = useState<Array<boolean>>([false]);
  const [pagFirstIndex, setPagFirstIndex] = useState<number>(0);

  const handleChange = (e: any, i: any) => {
    let arrayCopia: boolean[] = [...checkArray];
    arrayCopia[i] = !arrayCopia[i];
    setCheckArray(arrayCopia);
  };

  const paginaSelezionata = (i: any) => {
    let pagFindex = pagFirstIndex;
    let pagLindex = pagLastIndex;

    if (i === "prev") {
      if (currentPage === 1) {
        i = 1;
      } else {
        i = currentPage - 1;
        if (i < pagFirstIndex) {
          pagFindex = pagFindex - 1;
          pagLindex = pagLindex - 1;
        }
      }
    }

    if (i === "next") {
      if (currentPage === totalPages) {
        i = totalPages;
      } else {
        i = currentPage + 1;
        if (i >= pagLastIndex) {
          pagFindex = pagFindex + 1;
          pagLindex = pagLindex + 1;
        }
      }
    }

    let fIndex = i * 10;
    let lIndex = fIndex + 9;

    setCurrentPage(i);
    setPagFirstIndex(pagFindex);
    setPagLastIndex(pagLindex);
    renderPagination();
  };

  const pagination = () => {
    let buttons = [];
    if (totalPages == 1) {
      buttons.push(
        <button
          className={"btn-pagine-active"}
          key={1}
          id={"btn" + 1}
          onClick={() => {
            paginaSelezionata(1);
          }}
        >
          {1}
        </button>
      );
    } else {
      for (let i = 0; i < totalPages; i++) {
        buttons.push(
          <button
            className={
              currentPage === i + 1 ? "btn-pagine-active" : "btn-pagine"
            }
            key={i}
            id={"btn" + i}
            onClick={() => {
              paginaSelezionata(i + 1);
            }}
          >
            {i + 1}
          </button>
        );
      }
    }
    return buttons;
  };

  const renderPagination = () => {
    let buttons = pagination();
    let serie = [];
    for (let i = pagFirstIndex; i < pagLastIndex; i++) {
      serie.push(buttons[i]);
    }
    return serie;
  };

  let paginazione = (
    <Col className="text-right col-12">
      <button
        className="btn-pagine-left"
        key={"prev"}
        id="prev"
        onClick={() => {
          paginaSelezionata("prev");
        }}
      >
        {"<"}
      </button>
      {renderPagination()}
      <button
        className="btn-pagine-right"
        key={"next"}
        id="next"
        onClick={() => {
          paginaSelezionata("next");
        }}
      >
        {">"}
      </button>
    </Col>
  );
  return (
    <>
      <Table>
        {TableHeader({
          user,
          props,
          queryString,
          ...rest,
        })}
        <tbody>
          {TableBody({
            user,
            props,
            queryString,
            ...rest,
          })}
        </tbody>
      </Table>
      {paginazione}
    </>
  );
};

export default TableComponent;
