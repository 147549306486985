import React from "react";
import { useSelector } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

import { Button, Col, Input, Row } from "reactstrap";

import { AppState } from "../../../../store";
import { UtentiProps } from "../../types/utenti";

import { dictionaryGet } from "../../../../common/functions";
import applyFilters from "./applyFilters";

const InterfacciaRicerca = (utentiProps: UtentiProps) => {
  const {
    filters: {
      setOrderFiltersArray,
      setUserTypeFilterArray,
      setSearchText,
      setQueryString,
      orderFiltersArray,
      searchText,
    },
  } = utentiProps;
  const dictionary = useSelector((state: AppState) => state.dictionary);

  const cercaUtente = () => {
    applyFilters(0, utentiProps);
  };

  const resetAllFilters = () => {
    let orderClone = [...orderFiltersArray];
    orderClone.fill(0);
    setUserTypeFilterArray([
      { value: false, label: "ADMINISTRATOR" },
      { value: false, label: "USER" },
    ]);
    setOrderFiltersArray(orderClone);
    setSearchText("");
    setQueryString("");
  };

  return (
    <Row className="riga-utenti">
      <Col
        style={{ display: "inline-flex", padding: "10px" }}
        xs="8"
        md="8"
        lg="7"
        xl="8"
      >
        <Col md="10" lg="8" style={{ padding: "0px", maxWidth: "400px" }}>
          <Input
            value={searchText}
            className="input-cerca-inviti"
            name="nominativo"
            id="nominativo"
            placeholder={dictionaryGet(
              dictionary,
              "global.console.invitations.search"
            )}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
          />
        </Col>
        <Col md="1" lg="2" style={{ padding: "0px", maxWidth: "80px" }}>
          <Button
            className="btn-form-blue-cerca"
            color="primary"
            size="md"
            onClick={() => cercaUtente()}
          >
            <FontAwesomeIcon icon={faSearch} className="font-1xl" />
          </Button>
        </Col>
      </Col>
      <Col md="4" xs="4" lg="4" xl="4" style={{ padding: "15px" }}>
        <div className="div-btn-right">
          <Button
            className="btn-form-secondary interfaccia"
            color="secondary"
            size="md"
            onClick={() => {
              resetAllFilters();
            }}
          >
            {dictionaryGet(
              dictionary,
              "global.console.invitations.remove_filters"
            )}
          </Button>
        </div>
      </Col>
    </Row>
  );
};

export default InterfacciaRicerca;
