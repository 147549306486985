const calculatePagesFromCount = (
  count: number,
  setTotalPages: React.Dispatch<React.SetStateAction<number>>,
  setPagLastIndex: React.Dispatch<React.SetStateAction<number>>
) => {
  let totalePagine = Math.ceil(count / 10);
  setTotalPages(totalePagine);
  setPagLastIndex(10);
};
export default calculatePagesFromCount;
