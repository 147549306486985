import React, { useState, useEffect } from "react";
import { TabContent, TabPane, Spinner, Col, Button } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import UtentiPage from "./UtentiPage";
import { AppState } from "../../store";
import ServiceNav from "../../components/Navigation/ServiceNav";
import {
  fetchServicesList,
  canActivateOwner,
  setCurrentService,
} from "../../actions/user/actions";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import ActivateLicensesDialog from "../../components/Home/ActivateLicensesDialog";
import { dictionaryGet } from "../../common/functions";

export default function UtentiContainer(): JSX.Element {
  const currentService = useSelector(
    (state: AppState) => state.user.currentService
  );
  const selectedCompany = useSelector(
    (state: AppState) => state.user.current.selectedCompany
  );
  const [canActivate, setCanActivate] = useState<boolean>(false);
  const [openActivateModal, setActivateModal] = useState<boolean>(false);
  const thunkDispatch = useDispatch<ThunkDispatch<AppState, any, AnyAction>>();
  const dictionary = useSelector((state: AppState) => state.dictionary);

  const changeService = (tab: number) => {
    thunkDispatch(setCurrentService(tab));
  };

  const renderNavTabs = () => {
    if (selectedCompany != null) {
      return (
        <ServiceNav
          activeTab={currentService}
          changeService={changeService}
          selectedCompany={selectedCompany}
        />
      );
    }
  };

  const renderActivateOwnerLicenses = () => {
    if (canActivate) {
      return (
        <Col className="col-button" xs="4">
          <div className="alert-buttons">
            <Button
              onClick={() => {
                setActivateModal(true);
              }}
              style={{ width: "100%" }}
              className="btn-ambiti"
              color="primary"
              size="md"
            >
              {dictionaryGet(
                dictionary,
                "global.console.owner_licenses_activate"
              )}
            </Button>
          </div>
        </Col>
      );
    }
  };

  const closeModal = (idCompany: number) => {
    thunkDispatch(canActivateOwner(idCompany)).then((res: any) => {
      setActivateModal(false);
      setCanActivate(res.payload.data);
    });
  };

  useEffect(() => {
    if (selectedCompany !== null) {
      if (selectedCompany.isOwner) {
        thunkDispatch(canActivateOwner(selectedCompany.company.id)).then(
          (res: any) => {
            if (res !== undefined) {
              setCanActivate(res.payload.data);
            }
          }
        );
      }
    }
  }, []);

  useEffect(() => {
    if (selectedCompany !== null) {
      if (selectedCompany.service === null) {
        if (selectedCompany.isOwner) {
          thunkDispatch(fetchServicesList(selectedCompany.company.id));
        }
      }
      if (selectedCompany.isOwner) {
        thunkDispatch(canActivateOwner(selectedCompany.company.id)).then(
          (res: any) => {
            setCanActivate(res.payload.data);
          }
        );
      }
    }
  }, [selectedCompany]);

  return (
    <>
      {selectedCompany !== null ? (
        <ActivateLicensesDialog
          closeModal={closeModal}
          selectedCompany={selectedCompany}
          isModalOpen={openActivateModal}
        />
      ) : (
        ""
      )}
      <div id="page-gestione-utenti">
        {renderNavTabs()}
        <TabContent activeTab={currentService}>
          <TabPane tabId={currentService}>
            {renderActivateOwnerLicenses()}
            {selectedCompany !== null ? (
              selectedCompany.service !== null ? (
                selectedCompany.service.length > 0 ? (
                  <UtentiPage
                    selectedService={selectedCompany.service[currentService]}
                    selectedCompany={selectedCompany}
                  />
                ) : (
                  <Spinner />
                )
              ) : (
                <Spinner />
              )
            ) : (
              <Spinner />
            )}
          </TabPane>
        </TabContent>
      </div>
    </>
  );
}
