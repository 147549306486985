import axios from "axios";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { AppState } from "../../store";
import { URL } from "../../common/constants";
import { refreshToken } from "../user/actions";
import { getAccessTokenCookie } from "../../common/functions";

export const MAINTENANCE_SUCCESS = "MAINTENANCE_SUCCESS";
export const MAINTENANCE_FAILURE = "MAINTENANCE_FAILURE";

export const maintenanceSuccess = (response: any) => ({
  type: MAINTENANCE_SUCCESS,
  payload: response,
});
export const maintenanceFailure = (error: any) => ({
  type: MAINTENANCE_FAILURE,
  payload: error,
});

export function getMaintenanceStatus(): ThunkAction<
  Promise<any>,
  AppState,
  {},
  AnyAction
> {
  const AuthStr = "Bearer ".concat(getAccessTokenCookie());
  const url = `${URL.API_URL}/maintenance`;

  return async (
    dispatch: ThunkDispatch<AppState, {}, AnyAction>
  ): Promise<any> => {
    return axios
      .get(url, { headers: { Authorization: AuthStr } })
      .then((response) => {
        return dispatch(maintenanceSuccess(response));
      })
      .catch((error) => {
        dispatch(maintenanceFailure(error));
        if (error.response) {
          if (error.response.status === 403) {
            refreshToken(getMaintenanceStatus(), dispatch);
          }
        }
        throw error;
      });
  };
}
