import React from "react";
import { useSelector } from "react-redux";

import { Col, Row } from "reactstrap";

import { AppState } from "../../../../store";
import {
  ILicense,
  ILicensesWrapper,
} from "../../../../store/invitations/types";

import { dictionaryGet } from "../../../../common/functions";

import RenderAvailableLicenseDropdown from "./RenderAvailableLicenseDropdown";
import RenderUsedLicensesDropdown from "./RenderUsedLicensesDropdown";

type Props = {
  licenses: ILicensesWrapper;
};

const InfoLicenze = (props: Props) => {
  const dictionary = useSelector((state: AppState) => state.dictionary);

  const { licenses } = props;

  let avail = 0;
  let used = 0;
  if (licenses.data !== null) {
    licenses.data.map((license: ILicense) => {
      avail = avail + license.totalLicences;
      used = avail - license.tempBalance;
    });
  }
  const totaleSpan: JSX.Element = (
    <span style={{ color: "rgb(119, 188, 92)" }}>
      <b>{avail}</b>
    </span>
  );
  const usedSpan: JSX.Element = (
    <span style={{ color: "rgb(218, 88, 72)" }}>
      <b>{used}</b>
    </span>
  );
  return (
    <Row className="riga-licenze">
      <Col>
        <div className="div-licenze">
          <strong>
            {dictionaryGet(
              dictionary,
              "global.console.invitations.total_licenses"
            )}
          </strong>
          <span className="div-numero-licenze">
            {totaleSpan}
            {RenderAvailableLicenseDropdown(licenses)}
          </span>
        </div>
      </Col>
      <Col>
        <div className="div-licenze">
          <strong>
            {dictionaryGet(
              dictionary,
              "global.console.invitations.used_licenses"
            )}
          </strong>
          <span className="div-numero-licenze">
            {usedSpan}
            {RenderUsedLicensesDropdown(licenses)}
          </span>
        </div>
      </Col>
    </Row>
  );
};

export default InfoLicenze;
