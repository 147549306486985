import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faEllipsisH,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";

import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";

import { AppState } from "../../../../store";
import { IInvitation } from "../../../../store/invitations/types";

import { updateUser } from "../../../../actions/user/actions";

import { dictionaryGet } from "../../../../common/functions";
import { USER_TYPE, USER_TYPE_AS_API } from "../../../../common/constants";

import { UtentiProps } from "../../types/utenti";
import timeOut from "../../helpers/timeout";
import useOnEdit from "../../helpers/customHooks/useOnEdit";

import Switch from "react-switch";
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledButtonDropdown,
} from "reactstrap";

const TableBody = (utentiProps: UtentiProps) => {
  const {
    user,
    props,
    queryString,
    pagination: { currentPage, setTotalPages, setPagLastIndex },
    setters: {
      setActivationAlert,
      setUserIDToDelete,
      setEliminaModal,
      setEditUser,
      setOpenEditModal,
    },
  } = utentiProps;

  const users = useSelector((state: AppState) => state.user.list);
  const dictionary = useSelector((state: AppState) => state.dictionary);
  const thunkDispatch = useDispatch<ThunkDispatch<AppState, any, AnyAction>>();
  const onEdit = useOnEdit();

  const userType = (userType: string, isOwner: Boolean) => {
    let span: JSX.Element = (
      <span className="single-tag-violet">
        {dictionaryGet(dictionary, "global.console.owner")}
      </span>
    );
    if (isOwner) {
      return span;
    } else {
      if (userType === USER_TYPE_AS_API.ADMINISTRATOR) {
        return (
          <span className="single-tag-red">{USER_TYPE.ADMINISTRATOR}</span>
        );
      } else {
        return "";
      }
    }
  };

  const productsTD = (invito: IInvitation) => {
    if (invito.license.length > 1) {
      const dropItems = () => {
        for (let i = 1; i < invito.license.length; i++) {
          const lice = invito.license[i];
          return (
            <React.Fragment key={i}>
              <DropdownItem className="drop-item-solutions">
                <span className="single-tag-blue">{lice.name}</span>
              </DropdownItem>
            </React.Fragment>
          );
        }
      };

      return (
        <>
          <span className="single-tag-blue">{invito.license[0].name}</span>
          <UncontrolledButtonDropdown direction="right">
            <DropdownToggle className="more-solutions">
              <FontAwesomeIcon icon={faEllipsisH} />
            </DropdownToggle>
            <DropdownMenu className="dropmenu-filtro">
              <DropdownItem className="drop-item-solutions">Altre</DropdownItem>
              {dropItems()}
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </>
      );
    } else {
      if (invito.license.length > 0) {
        return (
          <span className="single-tag-blue">{invito.license[0].name}</span>
        );
      }
    }
  };

  const activateUser = (stato: boolean, id: number, userType: String) => {
    thunkDispatch(updateUser(id, stato, userType)).then((res: any) => {
      if (res.payload.result === "NOK") {
        let activationAlert = {
          isOpen: true,
          date: res.payload.error.errorMsg,
        };
        onEdit(
          user,
          props,
          currentPage,
          queryString,
          setTotalPages,
          setPagLastIndex
        );
        setActivationAlert(activationAlert);
        timeOut(setActivationAlert);
      } else {
        onEdit(
          user,
          props,
          currentPage,
          queryString,
          setTotalPages,
          setPagLastIndex
        );
      }
    });
  };

  const renderDeleteUserButton = (u: any) => {
    if (!u.isOwner && user.user != null && u.email != user.user.teamsystemId) {
      return (
        <Button
          onClick={() => {
            setUserIDToDelete(u.id);
            setEliminaModal(true);
          }}
          className="btn-secondario-danger"
          type="button"
        >
          <FontAwesomeIcon icon={faTrashAlt} />
        </Button>
      );
    }
  };

  if (users.data.length > 0) {
    return users.data.map((utente, i) => {
      return (
        <tr key={i}>
          <td>{utente.firstName}</td>
          <td>{utente.lastName}</td>
          <td>{utente.email}</td>
          <td style={{ textAlign: "center" }}>
            {userType(utente.userType, utente.isOwner)}
          </td>
          <td>{productsTD(utente)}</td>
          <td>
            <Switch
              className="switch-component-table"
              height={20}
              width={40}
              disabled={utente.isOwner}
              onColor={"#415ca3"}
              checked={utente.active}
              onChange={(checked, evt, id) => {
                activateUser(checked, utente.id, utente.userType);
              }}
            />
          </td>
          <td>
            <Button
              className="btn-modifica"
              onClick={() => {
                setEditUser(utente);
                setOpenEditModal(true);
              }}
              style={{ marginRight: "5px" }}
              type="button"
            >
              <FontAwesomeIcon icon={faEdit} />
            </Button>
            {renderDeleteUserButton(utente)}
          </td>
        </tr>
      );
    });
  } else {
    return (
      <tr>
        <th colSpan={8} style={{ textAlign: "center" }}>
          {dictionaryGet(
            dictionary,
            "global.console.users_management.no_users"
          )}
        </th>
      </tr>
    );
  }
};

export default TableBody;
