import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { AppState } from "../../../../store";
import { ICurrent } from "../../../../store/user/types";

import { getLicenses } from "../../../../actions/invitations/actions";
import { userCount, userSearch } from "../../../../actions/user/actions";

import { Props } from "../../types/utenti";
import calculatePagesFromCount from "../calculatePagesFromCount";

export const useOnEdit = () => {
  const thunkDispatch = useDispatch<ThunkDispatch<AppState, any, AnyAction>>();

  const onEdit = useCallback(
    (
      user: ICurrent,
      props: Props,
      currentPage: number,
      queryString: string,
      setTotalPages: React.Dispatch<React.SetStateAction<number>>,
      setPagLastIndex: React.Dispatch<React.SetStateAction<number>>
    ) => {
      if (user.selectedCompany !== null && props.selectedService !== null) {
        thunkDispatch(
          getLicenses(
            props.selectedService.code,
            user.selectedCompany.company.id
          )
        );
        thunkDispatch(
          userCount(
            props.selectedService.id,
            user.selectedCompany.company.id,
            currentPage,
            queryString
          )
        ).then((res: any) => {
          calculatePagesFromCount(
            res.payload.data,
            setTotalPages,
            setPagLastIndex
          );
        });
        thunkDispatch(
          userSearch(
            props.selectedService.id,
            user.selectedCompany.company.id,
            currentPage
          )
        );
      }
    },
    [thunkDispatch]
  );

  return onEdit;
};

export default useOnEdit;
