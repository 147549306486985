import React from "react";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { dictionaryGet } from "../../common/functions";
import { useSelector } from "react-redux";
import { AppState } from "../../store";

const Maintenance = () => {
  const dictionary = useSelector((state: AppState) => state.dictionary);

  return (
    <div className="div-download-under-maintenance">
      <FontAwesomeIcon size="lg" color="orange" icon={faExclamationTriangle} />
      <h5>{dictionaryGet(dictionary, "global.maintenance.title")}</h5>
      <small>{dictionaryGet(dictionary, "global.maintenance.sub_title")}</small>
    </div>
  );
};

export default Maintenance;
