import { combineReducers } from "redux";
import { userReducer } from "./user/reducers";
import { invitationsReducer } from "./invitations/reducers";
import { catalogReducer } from "./catalog/reducers";
import { authorizationReducer } from "./authorizations/reducers";
import { dictionaryReducer } from "./dictionary";
import { maintenanceReducer } from "./maintenance/reducers";

export const rootReducer = combineReducers({
  user: userReducer,
  invitations: invitationsReducer,
  catalog: catalogReducer,
  authorizations: authorizationReducer,
  dictionary: dictionaryReducer,
  maintenance: maintenanceReducer,
});

export type AppState = ReturnType<typeof rootReducer>;
