import {
  MAINTENANCE_SUCCESS,
  MAINTENANCE_FAILURE,
} from "../../actions/maintenance/maintenance";
import { maintenanceType } from "../../types/maintenance";

interface MaintenanceState {
  data: maintenanceType[];
  error: any;
  loading: boolean;
}

const initialState: MaintenanceState = {
  data: [],
  error: null,
  loading: false,
};

export const maintenanceReducer = (
  state = initialState,
  action: any
): MaintenanceState => {
  switch (action.type) {
    case "MAINTENANCE_LOADING":
      return { ...state, loading: true };
    case MAINTENANCE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: null,
      };
    case MAINTENANCE_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
