import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";

import { AppState } from "../../../../store";
import { UtentiProps } from "../../types/utenti";

import { dictionaryGet } from "../../../../common/functions";
import renderFilter from "../../helpers/renderFilter";
import dropDownTipo from "./dropdown/dropDownTipo";

const TableHeader = (utentiProps: UtentiProps) => {
  const {
    filters: { orderFiltersArray, setOrderFiltersArray },
  } = utentiProps;
  const dictionary = useSelector((state: AppState) => state.dictionary);

  const setOrderFilter = (idx: number) => {
    let clone = [...utentiProps.filters.orderFiltersArray];
    switch (clone[idx]) {
      case 0:
        clone.fill(0);
        clone[idx] = 1;
        break;
      case 1:
        clone.fill(0);
        clone[idx] = 2;
        break;
      case 2:
        clone.fill(0);
        clone[idx] = 0;
        break;
    }
    setOrderFiltersArray(clone);
  };

  return (
    <thead>
      <tr>
        <th>
          <span>
            {dictionaryGet(dictionary, "global.console.invitations.first_name")}
          </span>
        </th>
        <th>
          <span>
            {dictionaryGet(dictionary, "global.console.invitations.last_name")}
          </span>
          <span
            onClick={() => {
              setOrderFilter(0);
            }}
          >
            <FontAwesomeIcon
              className="icona-filtro"
              icon={renderFilter(0, orderFiltersArray)}
            />
          </span>
        </th>
        <th>
          <span>Email</span>
          <span
            onClick={() => {
              setOrderFilter(1);
            }}
          >
            <FontAwesomeIcon
              className="icona-filtro"
              icon={renderFilter(1, orderFiltersArray)}
            />
          </span>
        </th>
        <th style={{ textAlign: "center" }}>
          <span>
            {dictionaryGet(dictionary, "global.console.invitations.type")}
          </span>
          {dropDownTipo(utentiProps)}
        </th>
        <th>
          <span>
            {dictionaryGet(dictionary, "global.console.invitations.solutions")}
          </span>
        </th>
        <th>
          <span>{dictionaryGet(dictionary, "global.console.active")}</span>
        </th>
        <th />
      </tr>
    </thead>
  );
};

export default TableHeader;
