const timeOut = (setActivationAlert: React.Dispatch<any>) => {
  setTimeout(function () {
    let obj = {
      isOpen: false,
      date: null,
    };
    setActivationAlert(obj);
  }, 3000);
};

export default timeOut;
