// src/helpers/useMaintenanceStatus.ts
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { getMaintenanceStatus } from "../../actions/maintenance/maintenance";
import { AppState } from "../../store";

export const useMaintenanceStatus = () => {
  const dispatch = useDispatch();
  const maintenanceStatus = useSelector((state: AppState) => state.maintenance);

  useEffect(() => {
    dispatch(getMaintenanceStatus());
  }, [dispatch]);

  return maintenanceStatus;
};
