import { maintenanceType } from "../../types/maintenance";
import useCurrentRoute from "./useCurrentRoute";

const useUnderMaintenance = (maintenanceResponse: any) => {
  const currentRoute = useCurrentRoute();

  if (
    !maintenanceResponse ||
    !maintenanceResponse.data ||
    !maintenanceResponse.data.data
  ) {
    return undefined;
  }

  return maintenanceResponse.data.data.find(
    (item: maintenanceType) => item.route === currentRoute
  );
};

export default useUnderMaintenance;
