import React from "react";

import { dictionaryGet } from "../../../common/functions";
import { INSTALLER_CODE } from "../../../common/constants";
import { ICurrent } from "../../../store/user/types";
import { Route, Section } from "../../../types/maintenance";

import isUnderMaintenance from "../../../helpers/isUnderMaintenance";

import { Installer } from "../types/download";
import { DownloadCard } from "../common/DownloadCard";
import {
  download,
  downloadBIUtils,
  downloadBiManual,
} from "./downloadFunctions";

export const renderQlikHistory = (
  installers: Installer | null,
  dictionary: any
) => {
  if (
    installers != null &&
    installers.QLIK.legacy != null &&
    installers.QLIK.legacy.length > 0
  ) {
    return (
      <a className="qlik-history-link" href="#/download-qlik-history">
        {dictionaryGet(dictionary, "global.console.download.qlik_link")}
      </a>
    );
  }
};

export const renderCards = (
  installers: Installer | null,
  dictionary: any,
  current: ICurrent,
  thunkDispatch: any,
  setIsLoading: (loading: boolean) => void,
  routeUnderMaintenance: any,
  isLoading: boolean
) => {
  if (installers !== null) {
    return (
      <>
        <DownloadCard
          title="TS Enterprise BI 2019/2022"
          logoPath={process.env.PUBLIC_URL + "/images/installers/BI.png"}
          version={installers.BI.version}
          dictionary={dictionary}
          isLoading={isLoading}
          onDownload={() =>
            download(
              INSTALLER_CODE.BI,
              installers,
              current,
              thunkDispatch,
              setIsLoading
            )
          }
          isMaintenance={isUnderMaintenance(
            routeUnderMaintenance,
            Route.Download,
            Section.BiApp
          )}
        />
        <DownloadCard
          title="BI Utilities"
          logoPath={
            process.env.PUBLIC_URL + "/images/installers/BI_utilities.png"
          }
          version={installers.BI.biUtilitiesVersion}
          dictionary={dictionary}
          isLoading={isLoading}
          onDownload={() => downloadBIUtils(current)}
          isMaintenance={isUnderMaintenance(
            routeUnderMaintenance,
            Route.Download,
            Section.BiUtilities
          )}
        />
        <DownloadCard
          title={dictionaryGet(dictionary, "global.console.guide")}
          logoPath={process.env.PUBLIC_URL + "/images/installers/BI_guide.png"}
          version={installers.BI.version}
          dictionary={dictionary}
          isLoading={isLoading}
          onDownload={() => downloadBiManual(current)}
          isMaintenance={false}
        />
      </>
    );
  }
  return null;
};
