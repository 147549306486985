import { UtentiProps } from "../../types/utenti";
import { ORDER_BY, ORDER_DIR } from "../../../../common/constants";

const applyFilters = (idx: number, UtentiProps: UtentiProps) => {
  const {
    queryString,
    filters: {
      solutionsFilterArray,
      orderFiltersArray,
      userTypeFilterArray,
      typeFilter,
      searchText,
      setQueryString,
    },
    dropdowns: { dropTipo, dropSoluzioni, setDropTipo, setDropSoluzioni },
    setters: { setSolutionsFilter, setTypeFilter },
  } = UtentiProps;

  let stringPayload = queryString;
  let esito = orderFiltersArray.some((val) => {
    return val > 0;
  });
  let usTypeFilter = "";
  let solFilter = "";
  let orderFilter = "";
  let typeOrderFilter = "";
  let search = "";

  userTypeFilterArray.map((val) => {
    if (val.value) {
      usTypeFilter = "&userType=" + val.label;
    }
  });
  setTypeFilter(typeFilter);

  if (searchText.length > 1) {
    search = "&name=" + searchText;
  }

  solutionsFilterArray.map((solution) => {
    if (solution.value) {
      solFilter = "&license=" + solution.label;
    }
  });

  if (esito) {
    orderFiltersArray.map((val, i) => {
      if (val > 0) {
        if (val === 1) {
          orderFilter = "&orderDir=" + ORDER_DIR[0];
          typeOrderFilter = "&orderBy=" + ORDER_BY[i];
        }
        if (val === 2) {
          orderFilter = "&orderDir=" + ORDER_DIR[1];
          typeOrderFilter = "&orderBy=" + ORDER_BY[i];
        }
      }
    });
  }

  stringPayload =
    usTypeFilter + solFilter + typeOrderFilter + orderFilter + search;

  setSolutionsFilter(solFilter);
  setQueryString(stringPayload);

  if (idx === 1) {
    setDropTipo(!dropTipo);
  }
  if (idx === 2) {
    setDropSoluzioni(!dropSoluzioni);
  }
};
export default applyFilters;
