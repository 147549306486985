import React from "react";
import {
  Button,
  ButtonDropdown,
  CustomInput,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";

import { USER_TYPE } from "../../../../../common/constants";
import { UtentiProps } from "../../../types/utenti";

import applyFilters from "../../Interfaccia/applyFilters";

const DropDownTipo = (utentiProps: UtentiProps) => {
  const {
    dropdowns: { dropTipo, setDropTipo, dropSoluzioni, setDropSoluzioni },
    filters: {
      userTypeFilterArray,
      solutionsFilterArray,
      setOrderFiltersArray,
      setUserTypeFilterArray,
      setSolutionsFilterArray,
    },
    setters: { setTypeFilter, setSolutionsFilter },
  } = utentiProps;

  const toggleDrop = (e: any, idx: number) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    if (idx === 1) {
      setDropSoluzioni(!dropSoluzioni);
      setDropTipo(false);
    }
    if (idx === 2) {
      setDropSoluzioni(false);
      setDropTipo(!dropTipo);
    }
  };

  const resetTypeFilters = () => {
    let clone = [...userTypeFilterArray];
    clone.map((res) => {
      res.value = false;
    });
    let cloneSolutions = [...solutionsFilterArray];
    cloneSolutions.map((sol) => {
      sol.value = false;
    });
    setSolutionsFilter("");
    setSolutionsFilterArray(cloneSolutions);
    setTypeFilter("");
    setUserTypeFilterArray(clone);
    setOrderFiltersArray([0, 0, 0]);
    // applyFilters(1);
  };

  const handleChangeFiltro = (e: any, i: number, idx: number) => {
    e.nativeEvent.stopImmediatePropagation();
    e.preventDefault();
    e.stopPropagation();
    if (idx === 1) {
      let array = [...userTypeFilterArray];
      array.map((val) => {
        val.value = false;
      });
      array[i].value = !array[i].value;
      setUserTypeFilterArray(array);
    }
    if (idx === 2) {
      let array = [...solutionsFilterArray];
      array[i].value = !array[i].value;
      setSolutionsFilterArray(array);
    }
  };

  return (
    <ButtonDropdown
      toggle={() => {}}
      isOpen={dropTipo}
      className="dropdown-filtro"
    >
      <DropdownToggle
        onClick={(e) => {
          toggleDrop(e, 2);
        }}
      >
        <FontAwesomeIcon icon={faFilter} />
      </DropdownToggle>
      <DropdownMenu className="dropmenu-filtro">
        {userTypeFilterArray.length > 0
          ? userTypeFilterArray.map((usrType, i) => {
              return (
                <DropdownItem key={i} className="drop-item">
                  <div className="item-filtro">
                    <span>
                      <CustomInput
                        checked={usrType.value}
                        value={usrType.label}
                        type="radio"
                        id={"check-user" + i}
                        onChange={(e) => {
                          handleChangeFiltro(e, i, 1);
                        }}
                      />
                    </span>
                    <span className="opzione-filtro">
                      {Object.keys(USER_TYPE).includes(usrType.label)
                        ? USER_TYPE[usrType.label as keyof typeof USER_TYPE]
                        : usrType.label}
                    </span>{" "}
                  </div>
                </DropdownItem>
              );
            })
          : ""}
        <div className="drop-menu-controls">
          <Button
            className="secondary"
            onClick={() => {
              applyFilters(1, utentiProps);
            }}
          >
            Ok
          </Button>
          <Button
            className="secondary"
            onClick={() => {
              resetTypeFilters();
            }}
          >
            Reset
          </Button>
        </div>
      </DropdownMenu>
    </ButtonDropdown>
  );
};

export default DropDownTipo;
