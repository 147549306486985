import { INSTALLER_CODE } from "../../../common/constants";
import { ICurrent } from "../../../store/user/types";
import {
  getBIManualUrl,
  getBIUtils,
  getInstallerBi,
  getInstallerQlik,
  getInstallerQlikVersion,
} from "../../../actions/download/actions";
import { Installer } from "../types/download";

export const downloadBiManual = (current: ICurrent) => {
  if (current.selectedCompany !== null) {
    let idCompany = current.selectedCompany.company.id;
    getBIManualUrl(idCompany).then((ress: any) => {
      window.open(ress.data);
    });
  }
};

export const downloadBIUtils = (current: ICurrent) => {
  if (current.selectedCompany !== null) {
    let idCompany = current.selectedCompany.company.id;
    getBIUtils(idCompany).then((ress: any) => {
      window.open(ress.data);
    });
  }
};

export const DownloadVersion = (
  type: INSTALLER_CODE,
  fileName: "",
  current: ICurrent,
  thunkDispatch: any
) => {
  if (current.selectedCompany !== null) {
    return thunkDispatch(
      getInstallerQlikVersion(current.selectedCompany.company.id, fileName)
    ).then((res: any) => {
      window.open(res.payload.data);
    });
  }
};

export const download = (
  type: INSTALLER_CODE,
  installers: Installer | null,
  current: ICurrent,
  thunkDispatch: any,
  setIsLoading: any
) => {
  if (current.selectedCompany !== null) {
    if (type === INSTALLER_CODE.BI) {
      setIsLoading(true);
      return thunkDispatch(
        getInstallerBi(
          installers,
          current,
          current.selectedCompany.company.id,
          current.selectedCompany.company.guid
        )
      ).then((res: any) => {
        setIsLoading(false);
      });
    } else {
      return thunkDispatch(
        getInstallerQlik(current.selectedCompany.company.id)
      ).then((res: any) => {
        window.open(res.payload.data);
      });
    }
  }
};
