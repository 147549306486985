import { maintenanceType, Route, Section } from "../types/maintenance";

const isUnderMaintenance = (
  routeUnderMaintenance: maintenanceType | undefined,
  routeToCheck: Route,
  sectionToCheck: Section
) => {
  return (
    routeUnderMaintenance &&
    routeUnderMaintenance.route === routeToCheck &&
    routeUnderMaintenance.sections.includes(sectionToCheck as never) &&
    routeUnderMaintenance.underMaintenance
  );
};

export default isUnderMaintenance;
